<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on" color="secondary" class="ml-2">
        <span class="d-none d-sm-flex">
          {{ activeLocale ? activeLocale.languageType.description : "" }}
          {{ activeLocale ? "(" + activeLocale.countryType.isoAlpha2 + ")" : "" }}
        </span>
        <span class="d-flex d-sm-none">
          {{ activeLocale ? activeLocale.languageType.name : "" }}
          {{ activeLocale ? activeLocale.countryType.isoAlpha2 : "" }}
        </span>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item v-for="(locale, i) in locales" :key="i" @click="onActivateLocale(locale)">
          <v-list-item-content>
            <v-list-item-title
              >{{ locale.languageType.description }} ({{ locale.countryType.isoAlpha2 }})
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { SELECT_COUNTRY, SELECT_LOCALE, SELECT_LOCALE_TRANSLATION } from "@/gapp-components/store/locale.module";
import { mapGetters } from "vuex";

export default {
  name: "LocaleDropdown",
  props: {
    routeToDashboard: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    locales: [],
    activeLocale: undefined
  }),
  methods: {
    onActivateLocale(locale) {
      this.activeLocale = locale;
      this.$store.dispatch(SELECT_LOCALE, locale).then(() => {
        this.$api.get("/api/types/address/countries/" + this.selectedLocale.countryType.name).then(({ data }) => {
          this.$store.dispatch(SELECT_COUNTRY, data);
          this.$api
            .post("/api/localeTranslations/search?size=1000", {
              locale: { id: locale.id }
            })
            .then(({ data }) => {
              this.$store.dispatch(SELECT_LOCALE_TRANSLATION, data.content).then(() => {
                if (this.routeToDashboard) {
                  this.$router.push("/").catch(() => {});
                }
              });
            });
        });
      });
    },
    fetchLocales() {
      this.locales = [];
      if (this.selectedProgram) {
        return this.$api.get("/api/locales").then(({ data }) => {
          this.locales = data;
          if (this.selectedLocale) {
            this.activeLocale = this.selectedLocale;
          }
        });
      } else {
        return Promise.resolve(this.locales);
      }
    }
  },
  watch: {
    selectedProgram() {
      this.fetchLocales();
    }
  },
  mounted() {
    this.fetchLocales();
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedLocale", "selectedCountry"])
  }
};
</script>
